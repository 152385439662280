<template>
  <div class="overview">
      <div class="overview__header">
          <div class="page-title">{{ pageTitle }}</div>

          <FormulateInput
              v-if="searchable"
              type="search"
              name="search"
              class="custom-light-search"
              :label="searchOptions.label"
              :placeholder="searchOptions.placeholder"
              v-model="search"
              @input="searchQuery"
          />
      </div>

      <TableCard :settings="settings" :data-array="tableList" @sort-up="sortUpRow" @sort-down="sortDownRow" />

      <div v-if="footerButtons.length" class="d-flex justify-content-end mt-4">
          <Button
              :key="idx"
              v-for="(button, idx) in footerButtons"
              :label="button.label"
              :variant="button.variant"
              :styles="{ padding: '8px 15px', fontWeight: 700 }"
              class="ml-4"
              :action="button.action"
          />
      </div>

      <slot name="optional-content"></slot>

      <nav
          aria-label="Table overview navigation"
          class="navigation mt-4"
          v-if="getPage && pagination && pagination.total && pagination.total > 1"
      >
          <ul class="pagination">
              <li
                  :class="['page-item', { active: page === pagination.current }]"
                  v-for="page in pagination.total"
                  :key="page"
                  @click="setPage( page )"
              >
                  <span class="page-link">{{ page }}</span>
              </li>
          </ul>
      </nav>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import TableCard from "@/components/Table/TableCard.vue"
import Button from "@/components/Buttons/Button"

export default {
  name: "TableOverview",
  components: {
      TableCard,
      Button
  },
  props: {
      kind: {
          type: String,
          required: true
      },
      pageTitle: {
          type: String,
          default: ""
      },
      searchable: {
          type: Boolean,
          default: false
      },
      searchOptions: {
          type: Object,
          default: () => ({ label: "", placeholder: "" })
      },
      settings: {
          type: Object,
          required: true
      },
      footerButtons: {
          type: Array,
          default: () => []
      },
      getPage: {
          type: [ Function, Boolean ],
          default: false
      },
      sortUpFn: {
          type: [ Function, Boolean ],
          default: false
      },
      sortDownFn: {
          type: [ Function, Boolean ],
          default: false
      },
      entities: {
          type: [ Array, Boolean ],
          default: false
      }
  },
  data() {
      return {
          tableList: [],
          searchPlaceholder: "",
          search: ""
      }
  },
  computed: {
      ...mapState( '_module', {
          singleData: 'singleData',
      }),
      storeKind() {
          if (["module", "export"].includes(this.kind)) return `_${this.kind}`
          return this.kind
      },
      entityList() {
          return this.$store.getters[`${this.storeKind}/${this.kind}List`];
      },
      pagination() {
          return this.$store.state.helper.tablePagination;
      },
  },
  methods: {
      ...mapMutations( '_module', {
          updateModulesList: 'updateModulesList',
      } ),
      sortUpRow(idx) {
          if (this.sortUpFn !== false) {
              this.sortUpFn(this, idx);
          } else {
              let temp = this.tableList[idx];
              this.tableList[idx] = this.tableList[idx - 1];
              this.tableList[idx - 1] = temp;
              this.updateModulesList(this.tableList);
          }
      },
      sortDownRow(idx) {
          if (this.sortDownFn !== false) {
              this.sortDownFn(this, idx);
          } else {
              let temp = this.tableList[idx];
              this.tableList[idx] = this.tableList[idx + 1];
              this.tableList[idx + 1] = temp;
              this.updateModulesList(this.tableList);
          }
      },
      searchQuery() {
          if (this.searchable) {
              this.tableList = this.entityList.filter((item) => {
                  return item.title.match(this.search)
              })
          }
      },
      setPage( page ) {
          if ( this.getPage ) {
              this.getPage( page );
          }
      },
      loadEntities() {
          if (this.entities !== false) {
              this.tableList = this.entities;

              return;
          }
          
          if (this.entityList?.length) {
              this.tableList = JSON.parse(JSON.stringify(this.entityList));

              return;
          }

          if (this.getPage !== false) {
              console.log('Entities not found, loading them with provided getPage function...');

              this.getPage(1);
          }
      }
  },
  watch: {
      entityList: {
          deep: true,
          handler() {
              if (this.entities === false) {
                  this.tableList = JSON.parse(JSON.stringify(this.entityList));
              }
          }
      },
      entities() {
          if (this.entities !== false) {
              this.tableList = this.entities;
          }
      },
      $route( to, from ) {
          if ( to.name === `create_${this.kind}` || from.name === `create_${this.kind}` ) {
              return;
          }

          let fromPage = '1';
          if ( from.query.page ) fromPage = from.query.page;

          if (
              to.query.page !== from.query.page &&
              to.name === from.name &&
              this.pagination.current == fromPage &&
              !( !from.query.page && to.query.page == '1' )
          ) {
              let page = to.query.page ? to.query.page : '1';
              // this.setStatusLoadingSkeleton( false );
              this.getPage( page );
          } else {
              if ( ('user' in from.query) && !('user' in to.query) || ('user' in to.query) && !('user' in from.query) ) {
                  // this.setStatusLoadingSkeleton( false );
                  this.getPage( 1 );
              }
          }
      },
  },
  mounted() {
      this.loadEntities();
  }
}
</script>

<style lang="scss" scoped>

.overview {
  &__header {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      .page-title {
          font-family: "Oswald", sans-serif;
          text-transform: uppercase;
          margin-right: 25px;
          color: $dark_text;
          font-weight: 400;
          font-size: 1rem;
      }
  }

  .navigation {
      width: max-content;
      margin: 0 auto;

      .pagination {

          .page-item {
              cursor: pointer;
          }
      }
  }
}
</style>